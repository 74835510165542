<template>
         <znl-chart-mini
          titles="销售趋势"
          :xyoption="line"
          :loading="loading"
          :grid-show="true"
          width="100%"
          height="300px"
          :on-init="onInit"
          backgroundColor="transparent"
          class="index-charts"
          :on-refresh="pageSearch">
          <div slot="znl-action">
              <!-- <el-select v-model="userid"
                   placeholder="全部"
                   size="mini"
                   @change="pageSearch" class="searchCol">
                     <el-option v-for="item in users"
                     :key="item.UserID"
                     :label="item.UserName"
                     :value="item.UserID"></el-option>
              </el-select> -->
              <!-- <znl-input
                form-type="select"
                layout="left"
                width="200px"
                :clearable="true"
                placeholder="全部"
                :select-options="users"
                v-model="userid"
                @change="pageSearch"
              ></znl-input> -->
              <znl-input
                  title-width="0px"
                  layout="left"
                  form-type="select"
                  :disabled="false"
                  width="200px"
                  placeholder="请选择"
                  popper-class="popperClass"
                  :is-must-fill="false"
                  :clearable="true"
                  @change="pageSearch"
                  :select-options="users"
                  v-model="userid"
                  >
              </znl-input>
          </div>
         </znl-chart-mini>
</template>

<script>
// import znlChart from '@c_common/znlGrid/znlChart'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
export default {
  name: 'SaleReport',
  components: {
    // znlChart
  },
  mixins: [getCommonDataMixin],
  data () {
    return {
      config: {
        getCompanyUsersURL: 'CompanyAccount/GetUserAllowCompanyUser',
        getMonthSales: 'SaleRpt/GetMonthSales'
      },
      userid: '',
      users: [],
      loading: false,
      line: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '80px',
          right: '20px',
          top: '30px',
          bottom: '30px'
        },
        xAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c5c5c5'
            }
          },
          data: []
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c5c5c5'
            }
          }
        },
        series: [{
          name: '金额',
          type: 'line',
          data: [],
          width: '500px',
          symbolSize: 8,
          itemStyle: {
            normal: {
              color: '#EE7700',
              lineStyle: {
                color: '#EE7700'
              },
              areaStyle: {
                type: 'default',
                // color: '#fef2e5'
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: '#EE7700' // 0% 处的颜色
                  }, {
                    offset: 1, color: '#EEF2F5' // 100% 处的颜色
                  }],
                  globalCoord: false // 缺省为 false
                }
              }
            }
          }
        }]
      }
    }
  },
  props: {
    AllUserURL: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    AllUserURL () {
      this.onInitUserList()
    }
  },
  methods: {
    async onInit () {
    },

    // 初始化员工列表
    onInitUserList () {
      let AllUserURL = JSON.parse(JSON.stringify(this.AllUserURL))
      if (AllUserURL.length > 1) {
        AllUserURL.unshift({ key: 0, value: '全部' })
        this.userid = 0
      } else {
        this.userid = AllUserURL[0].UserID
      }
      this.users = AllUserURL
    },
    // 获取图表数据
    getChartData (userid) {
      // console.log('111')
      // return this.$post(this.config.getMonthSales, { UserID: userid || null }, (data) => {
      //   this.line.xAxis.data = _.map(data, item => { return item.Month + '月' })
      //   this.line.series[0].data = _.map(data, item => { return item.OrderAmount })
      // }).then(() => {
      //   _.delay(() => {
      //     this.loading = false
      //   }, 200)
      // })
      this.line.xAxis.data = []
      this.line.series[0].data = []
      this.loading = false
    },
    // 搜索
    pageSearch (val) {
      this.loading = true
      this.getChartData(_.isUndefined(val) ? null : val.key)
    }
  },
  mounted () {
    this.pageSearch()
    // console.log('1111')
  }
}
</script>

<style lang="scss">
.searchCol {
  width: 150px;
}
.znl-grid-chart .znl-action {
  line-height: 0 !important;
}
.znl-header-text {
  height: 28px !important;
  line-height: 28px !important;
}
</style>
