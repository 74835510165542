<template>
  <div class="p-home">
    <p class="p-home-title"><span>欢迎使用, 正能量ERP</span></p>
    <div class="p-home-img">
      <img src="~assets/images/home.png"/>
    </div>
  </div>
</template>

<script>
import {Row, Col} from 'element-ui'
import ZnlSaleRpt from '@c_modules/Rpt/SaleRpt'
import ZnlSaleChart from '@c_modules/Rpt/SaleChart'
import ZnlPurchaseChart from '@c_modules/Rpt/PurchaseChart'
import ZnlInquireChart from '@c_modules/Rpt/InquireChart'

export default {
  name: 'Index',
  components: {
  },
  data() {
    return {
      pageData: [],
      AllUserURL: []
    }
  },
  methods: {

  },
  async created() {

  }
}
</script>

<style lang="scss" scoped>
.p-home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #FFFFFF;
  .p-home-title {
    font-size: 30px;
    font-weight: 600;
  }
  .p-home-img {
    img {
      max-width: 60vw;
    }
  }
}
</style>
