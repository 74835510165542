<template>
<div>
  <znl-gridmodule
              ref="flexGrid"
              header="业绩分析"
              :on-init="onInit"
              gridtype="base"
              :item-source="[]"
              :columns="[]"
              class=""
              layout-type="other"
              :from-height="50"
              v-loading="loading"
              :is-show-frombtns="false"
              :has-znl-actions="false"
              class="index_sale_rpt znl-grid-component"
              :on-refresh="pageSearch">
    <div slot='from-module'>
      <el-row type="flex" align="middle">
        <el-col :span="8">
          <el-row type="flex">
            <!-- <el-col>
              <el-select v-model="month"
                     placeholder="本月"
                     size="mini"
                     @change="pageSearch"
                     class="searchCol">
                <el-option v-for="item in months"
                          :key="item.Time"
                          :label="item.Text"
                          :value="item.Time"></el-option>
              </el-select>
            </el-col> -->
            <el-col :span="12">
             <znl-input
                  title-width="0px"
                  layout="left"
                  form-type="select"
                  :disabled="false"
                  width="100%"
                  placeholder="本月"
                  popper-class="popperClass"
                  :is-must-fill="false"
                  :clearable="true"
                  @change="(val)=>{pageSearch(val, 'month', null)}"
                  :select-options="months"
                  v-model="month"
                  >
              </znl-input>
            </el-col>
          <!-- </el-row> -->
          <!-- <el-row type="flex"> -->
            <!-- <el-col  style="z-index:99">
              <el-select v-model="userid"
                     size="mini"
                     @change="pageSearch"
                     class="searchCol">
                <el-option v-for="item in users"
                          :key="item.UserID"
                          :label="item.UserName"
                          :value="item.UserID"></el-option> month userid
              </el-select>
            </el-col> -->
            <el-col :span="11" :offset="1">
              <znl-input
                    title-width="0px"
                    layout="left"
                    form-type="select"
                    :disabled="false"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    :is-must-fill="false"
                    :clearable="true"
                    @change="(val)=>{pageSearch(val, null, 'userid')}"
                    :select-options="users"
                    v-model="userid"
                    >
                </znl-input>
              </el-col>
          </el-row>
        </el-col>
        <el-col :offset="0"
                :span="16"
                class="total">
          <!--<div>-->
          <el-row class="statistics">
            <el-col>

              <span >总单数(销)：</span>
              <span
                v-text="s_totalBillCount"
                class="statistics-top"
                   ></span>
              <span>总金额(销)：</span>
              <span
                v-text="s_totalAmount"
                class="statistics-top"
                   ></span>
            </el-col>
          </el-row>
          <el-row class="statistics">
            <el-col>

              <span>总单数(采)：</span>
              <span
                v-text="p_totalBillCount"
                class="statistics-bottom"
                    ></span>
              <span>总金额(采)：</span>
              <span
                v-text="p_totalAmount"
                class="statistics-bottom"
                    ></span>

            </el-col>
          </el-row>
          <!--</div>-->
        </el-col>
      </el-row>
    </div>
    <div class="znl-index-table">
      <table class="calendar"
             cellspacing="0">
        <thead>
          <tr>
            <th>周一</th>
            <th>周二</th>
            <th>周三</th>
            <th>周四</th>
            <th>周五</th>
            <th>周六</th>
            <th>周日</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(week, index) in calendarData" :key="index">
            <td v-for="(day, i) in week" :key="i">
              <div v-if="day"
                   v-text="day.Day"
                   :class="getDateClass(day.Day, day.Month)"></div>
              <div>
                <div class="cai-container">
                  <el-row v-if="day"
                        v-show="day.P_BillCount>0"
                        class="cai cai-first">
                    <label class="label">采：</label>
                    <span class="billCountValue amountValue"
                          :span="12"><i class="iconfont icon-table_documents_ic"></i>{{day.P_BillCount||'-'}}</span>
                  </el-row>
                </div>
                <div class="cai-container">
                  <el-row v-if="day"
                        v-show="day.P_Amount!=0"
                        class="cai">
                    <el-tooltip :content="(day.P_Amount+'')||'-'" placement="top">
                      <div>
                        <label class="label"></label>
                        <span
                          :title="day.P_Amount||'-'"
                          :span="16"
                          class="amountValue"><i class="iconfont icon-table_money_ic"></i>{{day.P_Amount||'-'}}</span>
                      </div>
                    </el-tooltip>
                  </el-row>
                </div>
                <div class="amount-container">
                  <el-row v-if="day"
                        v-show="day.S_BillCount>0"
                        class="billCount amount">
                    <label class="label">销：</label>
                    <span class="billCountValue amountValue"

                          :span="12"><i class="iconfont icon-table_documents_ic"></i>{{day.S_BillCount||'-'}}</span>
                  </el-row>
                </div>
                <div class="amount-container">
                  <el-row v-if="day"
                        v-show="day.S_Amount!=0"
                        class="amount">
                    <el-tooltip :content="(day.S_Amount+'')||'-'" placement="top">
                      <div>
                        <label class="label"></label>
                        <span
                          :title="day.S_Amount||'-'"
                          :span="16"
                          class="amountValue"><i class="iconfont icon-table_money_ic"></i>{{day.S_Amount||'-'}}</span>
                      </div>
                    </el-tooltip>
                  </el-row>
                </div>
                <div class="enquiry-container">
                  <el-row v-if="day"
                        v-show="day.C_TotalCount>0"
                        class="enquiry enquiry-first">
                    <label class="label">询：</label>
                    <span class="billCountValue amountValue"
                          :span="12"><i class="iconfont icon-table_documents_ic"></i>{{day.C_TotalCount||'-'}}</span>
                  </el-row>
                </div>
                <div class="enquiry-container">
                  <el-row v-if="day"
                        v-show="day.C_CustomerCount!=0"
                        class="enquiry">
                    <el-tooltip :content="(day.C_CustomerCount+'')||'-'" placement="top">
                      <div>
                        <label class="label"></label>
                        <span
                          :title="day.C_CustomerCount||'-'"
                          :span="16"
                          class="amountValue"><i class="iconfont icon-table_client_ic"></i>{{day.C_CustomerCount||'-'}}</span>
                      </div>
                    </el-tooltip>
                  </el-row>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </znl-gridmodule>
</div>
</template>

<script>
// import ZnlModule from '@c_common/znlGrid/znlModule'
export default {

  name: 'SaleReport',
  components: {
    // ZnlModule
  },
  data () {
    return {
      config: {
        getCompanyUsersURL: 'CompanyAccount/GetUserAllowCompanyUser',
        getSales: 'HomeRpt/Search'
      },
      month: '',
      userid: '', // 为0表示全部
      saleSearchModel: {},
      months: [],
      users: [],
      s_totalBillCount: 0,
      s_totalAmount: 0,
      p_totalBillCount: 0,
      p_totalAmount: 0,
      calendarData: [],
      loading: true
    }
  },
  props: {
    AllUserURL: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    AllUserURL () {
      this.onInitUserList()
    }
  },
  methods: {
    async onInit () {
      this.onInitMonthList()
      this.onInitUserList()
      this.pageSearch()
    },
    // 初始化月份列表
    onInitMonthList () {
      // 老方法BEGIN：
      // 显示今年月份，从1月开始到本月
      // let currentDate = new Date()
      // let currentYear = currentDate.getFullYear()
      // let currentMonth = currentDate.getMonth() + 1
      // let tempMonths = [{ Time: currentYear + '-' + currentMonth + '-1', Text: '本月' }]
      // for (let i = currentMonth - 1; i > 0; i--) {
      //  tempMonths.push({ Time: currentYear + '-' + i + '-1', Text: currentYear + '年' + i + '月' })
      // }
      // 老方法END

      // 显示当前月 倒数12个月的月份
      let currentDate = new Date()
      let currentYear = currentDate.getFullYear()
      let currentMonth = currentDate.getMonth() + 1
      let tempMonths = [{ Time: currentYear + '-' + currentMonth + '-1', Text: '本月' }]
      for (let i = 1; i <= 11; i++) {
        currentMonth -= 1
        if (currentMonth === 0) {
          currentMonth = 12
          currentYear -= 1
        }
        tempMonths.push({ Time: currentYear + '-' + currentMonth + '-1', Text: currentYear + '年' + currentMonth + '月' })
      }
      //  :key="item.Time"
      // :label="item.Text"
      this.months = _.map(tempMonths, item => {
        return {
          key: item.Time,
          value: item.Text
        }
      })
      this.month = tempMonths[0].Time// 默认选中本月
    },
    // 初始化员工列表
    async onInitUserList () {
      // return this.$post(this.config.getCompanyUsersURL, {}, (data) => {
      //   let allUsers = _.map(data.Users, user => {
      //     return {
      //       UserID: user.UserID,
      //       UserName: user.UserName
      //     }
      //   })
      //   if (allUsers.length > 1) {
      //     allUsers.unshift({ UserID: 0, UserName: '全部' })
      //     this.userid = 0
      //   } else {
      //     this.userid = allUsers[0].UserID
      //   }
      //   this.users = _.map(allUsers, item => {
      //     return {
      //       key: item.UserID,
      //       value: item.UserName
      //     }
      //   })
      //   // :key="item.UserID"
      //   //                   :label="item.UserName"
      // })
      let AllUserURL = JSON.parse(JSON.stringify(this.AllUserURL))
      if (AllUserURL.length > 1) {
        AllUserURL.unshift({ key: 0, value: '全部' })
        this.userid = 0
      } else {
        this.userid = 0
      }
      this.users = AllUserURL
    },
    // 获取日历数据
    getCalendarData (month, userid) {
      // let tempData = [{Day: 1, BillCount: 5, Amount: 3000}, {Day: 2, BillCount: 5, Amount: 3000}, {Day: 3, BillCount: 5, Amount: 3000}, {Day: 4, BillCount: 5, Amount: 3000},
      // {Day: 5, BillCount: 5, Amount: 3000}, {Day: 6, BillCount: 5, Amount: 3000}, {Day: 7, BillCount: 5, Amount: 3000}, {Day: 8, BillCount: 5, Amount: 3000}, {Day: 9, BillCount: 5, Amount: 3000}, {Day: 10, BillCount: 5, Amount: 3000}, {Day: 11, BillCount: 5, Amount: 3000},
      // {Day: 12, BillCount: 5, Amount: 3000}, {Day: 13, BillCount: 5, Amount: 3000}, {Day: 14, BillCount: 5, Amount: 3000}, {Day: 15, BillCount: 5, Amount: 3000}, {Day: 16, BillCount: 5, Amount: 3000}, {Day: 17, BillCount: 5, Amount: 3000}, {Day: 18, BillCount: 5, Amount: 3000},
      // {Day: 19, BillCount: 5, Amount: 3000}, {Day: 20, BillCount: 5, Amount: 3000}, {Day: 21, BillCount: 5, Amount: 3000}, {Day: 22, BillCount: 5, Amount: 3000}, {Day: 23, BillCount: 5, Amount: 3000}, {Day: 24, BillCount: 5, Amount: 3000}, {Day: 25, BillCount: 5, Amount: 3000},
      // {Day: 26, BillCount: 5, Amount: 3000}, {Day: 27, BillCount: 5, Amount: 3000}, {Day: 28, BillCount: 5, Amount: 3000}, {Day: 29, BillCount: 5, Amount: 3000}, {Day: 30, BillCount: 5, Amount: 3000}]
      // return this.$post(this.config.getSales, { Month: month, UserID: userid || null }, (data) => {
      //   let tempData = data.Data
      //   this.fillEmptyCalendarData(tempData)
      //   this.calendarData = this.groupCalendarDataByWeek(tempData)
      //   // console.log(this.calendarData)
      //   this.s_totalBillCount = data.S_TotalBillCount
      //   this.s_totalAmount = data.S_TotalAmount
      //   this.p_totalBillCount = data.P_TotalBillCount
      //   this.p_totalAmount = data.P_TotalAmount
      // }).then(() => {
      //   _.delay(() => {
      //     this.loading = false
      //   }, 200)
      // })
      this.loading = false
    },
    // 搜索
    pageSearch (val, month, userid) {
      // console.log(val, month, userid)
      this.loading = true
      let m = this.month
      let u = this.userid
      if (_.isUndefined(val) && _.isUndefined(month) && _.isUndefined(userid)) {
        //  console.log(m, u)
        return this.getCalendarData(m, u)
      }
      if (val && !_.isNull(month)) {
        m = val.key
      } else if (val && !_.isNull(userid)) {
        u = val.key
      } else if (!val && !_.isNull(month)) {
        m = null
      } else if (!val && !_.isNull(userid)) {
        u = null
      }
      // console.log(m, u)
      this.getCalendarData(m, u)
    },
    // 获取日期样式，当天日期要标红
    getDateClass (day, month) {
      let now = new Date()
      let selectedMonth = new Date(this.month)
      let className = 'date'
      if (now.getFullYear() === selectedMonth.getFullYear() && now.getMonth() === selectedMonth.getMonth() && day === now.getDate()) {
        className += ' red'
      }
      if (now.getFullYear() === selectedMonth.getFullYear() && now.getMonth() === selectedMonth.getMonth()) {
        className += ' now'
      }
      return className
    },
    // 填充空的日历数据，使日期与星期对齐
    fillEmptyCalendarData (data) {
      // 获取搜索月1号是星期几
      let day = new Date(this.month).getDay()
      let fillCount = day === 0 ? 6 : day - 1// 星期天要填充6次，其它填充（day-1）次
      for (let i = 0; i < fillCount; i++) {
        data.unshift(null)
      }
      // 填充尾部的null
      // 获取这月有多少天
      let date = new Date(this.month)
      let totalDays = this.getDaysInOneMonth(date.getFullYear(), date.getMonth() + 1)
      let calendarRows = Math.ceil((totalDays - (7 - fillCount)) / 7) + 1
      for (let i = data.length; i < calendarRows * 7; i++) {
        data.push(null)
      }
    },
    // 按星期分组日历数据(明细数据时间必须是连续的，不能有间隔的)
    groupCalendarDataByWeek (detailData) {
      let groupData = []
      for (let i = 0; i < detailData.length; i++) {
        let index = Math.floor(i / 7)
        if (i % 7 === 0) {
          groupData[index] = []
        }
        groupData[index].push(detailData[i])
      }
      return groupData
    },
    // 获取XXXX年几月有多少天
    getDaysInOneMonth (year, month) {
      month = parseInt(month, 10)
      var d = new Date(year, month, 0)
      return d.getDate()
    }
  },
  created () {
    // this.onInit()
  }
}
</script>

<style lang="scss" scoped>
@import "app/assets/styles/_variables.scss";

.znl-grid {
  background-color: white !important;
  table {
    width: 100%;
    th {
      height: 30px;
      font-size: 14px;
      font-weight: normal;
      color: $white;
    }
    thead {
      background-color: #525a66;
    }
  }
}

//搜索条件
.searchCol {
  width: 150px;
  padding: 0 1px;
}

//日历表格
.calendar {
  background-color: white;
  border-collapse: collapse;
}

.calendar td {
  position: relative;
  border: 1px solid #e6eaee;
  height: 165px;
  width: 14%; // min-width: 130px;
  height: 150px;
  vertical-align: bottom;
  // min-width: 140px;
  // padding: 5px 0;
}

.calendar td label {
  color: #333333;
}

.calendar td .billCountValue {
  color: #333333;
  font-size: 12px;
  width: 56px;
}

.calendar td .date {
  // text-align: right;
  position: absolute;
  top: 10px;
  right: 5px; // margin: 10px 0px 5px 5px;
  height: 30px;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.calendar td .red {
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  background-color: #ee7700;
  color: $white;
  display: inline-block;
}

.calendar td .billCount,
.calendar td .amount {
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  // border-left: 2px solid #EE7700;
  border-left: 2px solid #1bb934;
}

.calendar td .billCount {
  // margin: 5px 0;
  // background-color: #fff1e5;
}

.calendar td .amount .amountLabel,
.calendar td .billCount .billLabel {
  width: 60px;
}

.calendar td .amount {
  // margin: 5px 0;
  // background-color: #fff1e5;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  background-color: #e8f8ea;
  &.billCount {
    margin-top: 2px;
  }
}
.amount-container {
  background-color: #e8f8ea;
}
.cai-container {
  background-color: #fff1e5;
  .cai {
    height: 20px;
    line-height: 20px;
    padding-left: 5px;
    background-color: #fff1e5;
    border-left: 2px solid rgb(233, 135, 37);
    // margin: 5px 0
    &.cai-first {
      margin-top: 2px;
    }
  }
}
.enquiry-container {
  background-color: #e9f4fd;
  position: relative;
  .enquiry {
    height: 20px;
    line-height: 20px;
    padding-left: 5px;
    background-color: #e9f4fd;
    border-left: 2px solid #269af3;
    // margin: 5px 0
    &.enquiry-first {
      margin-top: 2px;
    }
  }
}

.calendar td .amount .amountValue {
  color: #333333;
  width: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}
.amountValue {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
}

.total {
  text-align: right;
}

//总单数+总金额
.total label {
  font-size: 12px;
  color: #666666;
}

.total span {
  font-size: 10px;
  padding-right: 5px;
  // margin-right: 20px;
  font-weight: bold;
}

.totalAmount {
  color: #ff7700;
  font-weight: bold;
}
.calendar td .el-row.amount {
  color: #333333;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  // font-size: 12px;
}

@media screen and (max-width: 1640px) {
  // .amountLabel {
  //   width: 30%
  // }
  .calendar td .el-row.amount,
  .el-row.cai,
  .el-row.enquiry {
    color: #333333;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    // display: flex;
    position: relative;
    .label {
      // width: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    span {
      // margin-left: 25px;
      // position: relative;
      // top: -2px;
      .iconfont {
        font-size: 18px;
        position: relative;
        top: 1px;
      }
    }
  }
}

.znl-index-table {
  .calendar {
    width: 100%;
    thead {
      th {
        background-color: #525a66;
        color: #d8d8d8;
        height: 28px;
      }
    }
    .amount,
    .cai,
    .enquiry {
      height: 18px !important;
      line-height: 16px !important;
    }
  }
}
.statistics {
  height: 20px;
  line-height: 20px;
  span {
    color: #666;
  }
  .statistics-top {
    color: #ee7700;
  }
  .statistics-bottom {
    color: #30bf47;
  }
}
</style>
