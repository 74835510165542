<template>
         <znl-chart-mini
          titles="询价趋势"
          :xyoption="line"
          :loading="loading"
          :grid-show="true"
          width="100%"
          height="300px"
          :on-init="onInit"
          backgroundColor="transparent"
          class="index-charts"
          :on-refresh="pageSearch">
          <div slot="znl-action">

              <znl-input
                  title-width="0px"
                  layout="left"
                  form-type="select"
                  :disabled="false"
                  width="200px"
                  placeholder="请选择"
                  popper-class="popperClass"
                  :is-must-fill="false"
                  :clearable="true"
                  @change="change"
                  :select-options="users"
                  v-model="userid"
                  >
              </znl-input>
          </div>
         </znl-chart-mini>
</template>

<script>
// import znlChart from '@c_common/znlGrid/znlChart'
export default {
  name: 'SaleReport',
  components: {
    // znlChart
  },
  data () {
    return {
      starTime: 0,
      config: {
        getCompanyUsersURL: 'CompanyAccount/GetUserAllowCompanyUser',
        getMonth: 'InquireRpt/GetMonthInquire'
      },
      userid: '',
      users: [],
      loading: true,
      line: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '80px',
          right: '20px',
          top: '30px',
          bottom: '30px'
        },
        xAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c5c5c5'
            }
          },
          data: []
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c5c5c5'
            }
          }
        },
        series: [{
          name: '询价次数',
          type: 'line',
          data: [],
          width: '500px',
          symbolSize: 8,
          itemStyle: {
            normal: {
              color: '#269AF3',
              lineStyle: {
                color: '#269AF3'
              },
              areaStyle: {
                type: 'default',
                // color: '#269AF3'
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: '#269AF3' // 0% 处的颜色
                  }, {
                    offset: 1, color: '#EEF2F6' // 100% 处的颜色
                  }],
                  globalCoord: false // 缺省为 false
                }
              }
            }
          }
        }]
      }
    }
  },
  props: {
    AllUserURL: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    AllUserURL () {
      this.onInitUserList()
    }
  },
  methods: {
    async onInit () {
    },
    change (val) {
      if (new Date() - this.starTime > 500) {
        this.pageSearch(val)
        this.starTime = new Date()
      } else {
        this.userid = null
      }
    },
    // 初始化员工列表
    onInitUserList () {
      // return this.$post(this.config.getCompanyUsersURL, {}, (data) => {
      //   let allUsers = _.map(data.Users, user => {
      //     return {
      //       UserID: user.UserID,
      //       UserName: user.UserName
      //     }
      //   })
      //   if (allUsers.length > 1) {
      //     allUsers.unshift({ UserID: 0, UserName: '全部' })
      //     this.userid = 0
      //   } else {
      //     this.userid = allUsers[0].UserID
      //   }
      //   this.users = _.map(allUsers, item => {
      //     return {
      //       key: item.UserID,
      //       value: item.UserName
      //     }
      //   })
      // })
      let AllUserURL = JSON.parse(JSON.stringify(this.AllUserURL))
      if (AllUserURL.length > 1) {
        AllUserURL.unshift({ key: 0, value: '全部' })
        this.userid = 0
      } else {
        // this.userid = AllUserURL[0].UserID
      }
      this.users = AllUserURL
      // console.log(this.users)
    },
    // 获取图表数据
    getChartData (userid) {
      // return this.$post(this.config.getMonth, { UserID: userid || null }, (data) => {
      //   this.line.xAxis.data = _.map(data, item => { return item.Month + '月' })
      //   this.line.series[0].data = _.map(data, item => { return item.TotalCount })
      // }).then(() => {
      //   _.delay(() => {
      //     this.loading = false
      //   }, 200)
      // })
      this.loading = false
    },
    // 搜索
    pageSearch (val) {
      this.loading = true
      if (new Date() - this.starTime > 500) {
        this.getChartData(_.isUndefined(val) ? null : val.key)
        this.starTime = new Date()
      }
    }
  },
  mounted () {
    // this.onInit()
    // console.log('123')
    this.pageSearch()
  }
}
</script>

<style lang="scss" scoped>
.searchCol {
  width: 150px;
}
</style>
